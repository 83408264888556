import React from "react";
import { HookFormVeriPasswordInputProps } from "./VeriPasswordInput.types";
import { isValidPassword } from "../../../utils";
import { errorMessages } from "../../../defines";
import { buildErrorMessageForName } from "../../../react-hook-form";
import { VeriPasswordInput } from "./VeriPasswordInput";
import {
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
} from "../../../defines/password";

export function HookFormVeriPasswordInput<T>({
  invalidPasswordMessage,
  form,
  name,
  rules,
  ...props
}: HookFormVeriPasswordInputProps<T>) {
  return (
    <VeriPasswordInput
      {...form.register(name, {
        required: {
          value: !!props.isRequired,
          message: errorMessages.requiredField,
        },
        minLength: {
          value: PASSWORD_MIN_LENGTH,
          message:
            invalidPasswordMessage ||
            `Password must be at least ${PASSWORD_MIN_LENGTH} characters`,
        },
        maxLength: {
          value: PASSWORD_MAX_LENGTH,
          message:
            invalidPasswordMessage ||
            `Password must be at most ${PASSWORD_MAX_LENGTH} characters`,
        },
        validate: (value) =>
          isValidPassword(value as string)
            ? undefined
            : invalidPasswordMessage ||
              `Password must be at least ${PASSWORD_MIN_LENGTH} characters with one number or special character`,
        ...rules,
      })}
      error={buildErrorMessageForName(form, name)}
      {...props}
    />
  );
}

HookFormVeriPasswordInput.displayName = "HookFormVeriPasswordInput";
