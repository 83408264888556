import { ManagerModel, UserModel } from "../../api/models";
import { AuthData } from "../types/AuthData";
import { AuthQueryParams } from "../types/AuthQueryParams";
import Auth, { Manager } from "../Auth";
import { buildRouteUrl } from "../../utils";
import { authRoutes } from "../../defines/routes/auth";
import { isValidWhiteLabelDomain } from "../../utils/white-label";

export interface InvestorPortalAuthMatchParams {
  page: string;
  sub?: string;
}

export interface InvestorPortalAuthServerProps {
  currentUser?: { profile?: UserModel };
  inviteCode?: string;
  authData?: AuthData;
  redirectTo?: string;
  email?: string;
  token?: string;
}

export const loadAuthServerData = async (
  manager: ManagerModel | undefined,
  query: AuthQueryParams,
  params: InvestorPortalAuthMatchParams,
  auth: Auth,
  domain: string,
): Promise<InvestorPortalAuthServerProps> => {
  const result: InvestorPortalAuthServerProps = {};

  const callbackUrl =
    query.callback_url || process.env.RAZZLE_APP_DEFAULT_CALLBACK_URL;
  const template = query.t;
  const backUrl = query.back_to;

  const authData: AuthData = {
    callbackUrl,
    backUrl,
    baseUrl: domain,
    domain,
    mfaToken: query.mfa_token,
    userToken: query.user_token,
    inviteCode: query.invite_code,
    profile: auth.profile,
    template,
  };

  if (domain && isValidWhiteLabelDomain(domain)) {
    if (!manager) {
      manager = (await auth.getManagerByDomain(domain)) as Manager;
    }
    if (manager?.id) {
      authData.hasManager = !!manager;
      authData.name = manager.name || "Verivest";
      authData.domain = manager.companyUrl || "verivest.com";
      authData.logoIconUrl = manager.logoUrl || "";
      authData.printLogoUrl = manager.printLogoUrl || "";
    }
  }

  result.authData = authData;

  if (params.page === "Login") {
    if (query.email) {
      const user = await auth.loadUserByEmail(query.email);
      if (!user || !user.user_id) {
        return { ...result, redirectTo: "/signup?email=" + query.email };
      } else {
        return { ...result, currentUser: user, inviteCode: query.invite_code };
      }
    }

    return { ...result, currentUser: {}, inviteCode: "" };
  } else if (params.page === "signup" && params.sub === "activation") {
    if (query.token) {
      const response = await auth.getActivationInfo(query.token);
      if (!response) {
        return {
          ...result,
          redirectTo: buildRouteUrl(authRoutes.SIGNUP, undefined, {
            email: query.email,
          }),
        };
      } else {
        return { ...result, email: response.email, token: query.token };
      }
    }
  }

  return result;
};
