import { Alert, AlertIcon, Box, Flex, Grid, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, HookFormVeriInput, VeriInput } from "../../../components";
import { VeriHelpToolTip } from "../../../components/popover/VeriHelpToolTip";
import { authRoutes } from "../../../defines/routes/auth";
import { useBooleanState } from "../../../hooks";
import { PrivacyPolicyLink } from "../../../links/PrivacyPolicyLink";
import { TermsOfServiceLink } from "../../../links/TermsOfServiceLink";
import { useVeriForm } from "../../../react-hook-form";
import { buildRouteUrl, getServerError, isValidEmail } from "../../../utils";
import { AuthBox } from "../../AuthBox";
import { AuthContainer } from "../../AuthContainer";
import { fromSignupParam } from "../../authParamHelpers";
import { useAuthProcess } from "../../AuthProcessProvider";
import { useAuth } from "../../index";
import { getAuthCustomTemplate } from "../../templates";
import { AuthFormData } from "../../types/AuthFormData";
import { useAuthGetUrlQueryParams } from "../../useAuthGetUrlQueryParams";
import { PasswordRequirementsCheck } from "../../../components/form-controls/VeriPasswordInput";
import { HookFormVeriPasswordInput } from "../../../components/form-controls/VeriPasswordInput/HookFormVeriPasswordInput";

export const Signup: React.FC = () => {
  const history = useHistory();

  const { auth } = useAuth();
  const { authData } = useAuthProcess();
  const { email, inviteCode } = useAuthGetUrlQueryParams();

  const template = getAuthCustomTemplate(authData?.template || "") || undefined;

  const form = useVeriForm<AuthFormData>({
    defaultValues: { email: email || "" },
  });

  const [error, setError] = React.useState<string>("");
  const isLoading = useBooleanState(false);

  const signup = form.handleSubmit(async (data) => {
    isLoading.true();
    setError("");

    const signupData = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      password: data.password,
      ...(template?.showCompanyInSignUp
        ? { company_name: data.company || "" }
        : undefined),
    };

    try {
      const response = await auth.signup({
        ...signupData,
        invite_code: inviteCode,
      });
      if (response && response.errors) {
        isLoading.false();
        if (response.errors[0]?.code === "USER_EXISTS") {
          return setError("This user already exists, try logging in with it");
        }

        setError(getServerError(response.errors[0])?.message);
      } else if (response) {
        if (response.email_activation_required === true) {
          history.push(buildRouteUrl(authRoutes.VERIFY));
        } else {
          let callbackUrl = authData.callbackUrl || authData.backUrl || "";
          if (template?.showCompanyInSignUp) {
            callbackUrl = buildRouteUrl(
              callbackUrl,
              {},
              { [fromSignupParam]: "true" },
            );
          }

          await auth.loginThroughToken(response.token);
          window.location.href = (callbackUrl || "/") + "?ask_mfa_setup=1";
        }
      }
    } catch (e) {
      isLoading.false();
      setError("Something went wrong");
    }
  });

  const password = form.watch("password");

  return (
    <AuthContainer>
      <AuthBox
        titleLogoUrl={authData?.printLogoUrl}
        companyName={authData?.name}
        title={"Sign up"}
        subtitle={<>To continue to your investor portal.</>}
        showBackButton
        isSmHeader
      >
        <form onSubmit={signup} noValidate>
          {error && (
            <Alert status="error" mb={{ base: "20px", md: "40px" }} mt={7}>
              <AlertIcon />
              {error}
            </Alert>
          )}

          <Grid
            mt={error ? 1 : 7}
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            gap={6}
          >
            <VeriInput
              isRequired
              {...form.register("firstName", {
                required: "First name is required",
              })}
              label="First name"
              error={form.formState.errors?.firstName?.message}
              propsInput={{ autoFocus: true }}
            />
            <VeriInput
              isRequired
              {...form.register("lastName", {
                required: "Last name is required",
              })}
              label="Last name"
              error={form.formState.errors?.lastName?.message}
            />
          </Grid>
          <br />
          {template?.showCompanyInSignUp && (
            <HookFormVeriInput
              isRequired
              mb={6}
              form={form}
              name="company"
              label="Manager profile name"
              labelExtra={
                <VeriHelpToolTip>
                  Enter the name of your syndication, fund, or opportunity. This
                  will be displayed on the investor portal. You can update it
                  anytime.
                </VeriHelpToolTip>
              }
            />
          )}
          <VeriInput
            isRequired
            label="Email address"
            {...form.register("email", {
              required: "Email is required",
              validate: (value) =>
                isValidEmail(value) ? undefined : "Email is not valid",
            })}
            error={form.formState.errors?.email?.message}
          />
          <br />
          <HookFormVeriPasswordInput
            label={"Password"}
            form={form}
            name={"password"}
            invalidPasswordMessage={
              "Please follow the password requirements below."
            }
          />
          <Box mt={4}>
            <PasswordRequirementsCheck password={password} />
          </Box>

          <Box>
            <Flex
              mt={{ base: "20px", md: 8 }}
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Button
                isLarge
                width={{ base: "100%", md: "unset" }}
                isLoading={isLoading.state}
                label="Sign Up"
                type="submit"
              />
            </Flex>
            <Text mt="24px" fontSize="14px">
              By signing up, I agree to Verivest&apos;s <TermsOfServiceLink />{" "}
              and <PrivacyPolicyLink whiteSpace="nowrap" />.
            </Text>
          </Box>
        </form>
      </AuthBox>
    </AuthContainer>
  );
};
