import React from "react";
import { AuthData } from "./types/AuthData";

export interface UseAuthProcessProps {
  isAdmin: boolean;
  authData: AuthData;
  setAuthData: (authData: AuthData) => void;
  setAuthDataByKey: <K extends keyof AuthData>(
    key: K,
    value: AuthData[K],
  ) => void;
}

export interface AuthProcessProviderProps {
  authData: AuthData;
  isAdmin?: boolean;
}

const AuthProcessContext = React.createContext<UseAuthProcessProps | undefined>(
  undefined,
);

const AuthProcessProvider: React.FC<AuthProcessProviderProps> = ({
  children,
  isAdmin,
  authData: _authData,
}) => {
  const [authData, setAuthData] = React.useState(_authData);

  const setAuthDataByKey: UseAuthProcessProps["setAuthDataByKey"] = (
    key,
    value,
  ) => {
    setAuthData({ ...authData, [key]: value });
  };

  return (
    <AuthProcessContext.Provider
      value={{
        isAdmin: !!isAdmin,
        authData,
        setAuthData,
        setAuthDataByKey,
      }}
    >
      {children}
    </AuthProcessContext.Provider>
  );
};

const useAuthProcess = () => {
  const context = React.useContext(AuthProcessContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProcessProvider");
  }

  return {
    ...context,
    getAuthUrlParams: (authData?: AuthData) => {
      const _authData = authData || context.authData;

      const result: Record<string, string> = {
        callback_url: encodeURIComponent(_authData.callbackUrl || ""),
        user_token: encodeURIComponent(
          _authData.token || _authData.userToken || "",
        ),
        invite_code: encodeURIComponent(_authData.inviteCode || ""),
        mfa_token: encodeURIComponent(_authData.mfaToken || ""),
      };

      if (_authData?.template) {
        result.t = _authData.template;
      }

      if (_authData?.backUrl) {
        result.back_to = encodeURIComponent(_authData.backUrl || "");
      }

      return result;
    },
    getFinalUrl: (authData?: AuthData) => {
      const _authData = authData || context.authData;

      return _authData.callbackUrl + "?token=" + _authData.token;
    },
  };
};

export { AuthProcessProvider, useAuthProcess };
