import { useLocation } from "react-router-dom";

interface AuthQueryParams {
  email?: string;
  inviteCode?: string;
  template?: string;
  backUrl?: string;
  callbackUrl?: string;
}
export const useAuthGetUrlQueryParams = (): AuthQueryParams => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return {
    email: decodeURIComponent(queryParams.get("email") || ""),
    inviteCode: decodeURIComponent(queryParams.get("invite_code") || ""),
    template: decodeURIComponent(queryParams.get("template") || ""),
    backUrl: decodeURIComponent(queryParams.get("back_url") || ""),
    callbackUrl: decodeURIComponent(queryParams.get("callback_url") || ""),
  };
};
