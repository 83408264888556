import { Alert, AlertIcon, Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useAuth } from "../../index";
import { useAuthProcess } from "../../AuthProcessProvider";
import { useVeriForm } from "../../../react-hook-form";
import { AuthFormData } from "../../types/AuthFormData";
import { useBooleanState } from "../../../hooks";
import {
  isStringBetween,
  isValidEmail,
  isValidPassword,
  stringContainsSpecialOrNumber,
} from "../../../utils";
import { AuthContainer } from "../../AuthContainer";
import { AuthBox } from "../../AuthBox";
import { Button, VeriInput } from "../../../components";
import { CheckCircleIcon } from "../../../icons";
import { PrivacyPolicyLink } from "../../../links/PrivacyPolicyLink";
import { TermsOfServiceLink } from "../../../links/TermsOfServiceLink";

export const ActivateUser: React.FC<{ email: string; token: string }> = ({
  email,
  token,
}) => {
  const { auth } = useAuth();
  const { authData, getFinalUrl } = useAuthProcess();

  const form = useVeriForm<AuthFormData>({
    defaultValues: { email: email || "" },
  });
  const isLoading = useBooleanState(false);
  const [error, setError] = React.useState<string | null>(null);

  const activate = form.handleSubmit(async (data) => {
    isLoading.true();
    setError(null);

    const activationData = {
      email: data.email,
      password: data.password,
      token: token,
    };
    try {
      const response = await auth.activateImportedUser(activationData);
      if (response && response.error) {
        isLoading.false();
        return setError("Something went wrong");
      }

      authData.token = response.token;

      window.location.href = getFinalUrl(authData);

      isLoading.false();
      return setError(response.error);
    } catch (e) {
      isLoading.false();
      return setError("Something went wrong");
    }
  });

  const password = form.watch("password") || "";

  const isPasswordBetween = isStringBetween(8, 20, password);
  const isPasswordUsingNOrS = stringContainsSpecialOrNumber(password);

  return (
    <AuthContainer>
      <AuthBox
        titleLogoUrl={authData?.printLogoUrl}
        companyName={authData?.name}
        title={"Activate Account"}
        subtitle={"To continue to your investor portal."}
        isSmHeader
      >
        <form onSubmit={activate} noValidate>
          {error && (
            <Alert status="error" mb={{ base: "20px", md: "40px" }}>
              <AlertIcon />
              {error}
            </Alert>
          )}

          <VeriInput
            {...form.register("email", {
              required: "Email is required",
              validate: (value) =>
                isValidEmail(value) ? undefined : "Email is not valid",
            })}
            label={"Email"}
            error={form.formState.errors?.email?.message}
            propsInput={{ placeholder: "Email" }}
          />
          <br />
          <VeriInput
            {...form.register("password", {
              required: "Password is required",
              validate: (value) =>
                isValidPassword(value)
                  ? undefined
                  : "Password must be a minimum of 8 characters with one number or special character",
            })}
            label={"Password"}
            propsInput={{ placeholder: "Password" }}
            type="password"
            error={form.formState.errors?.password?.message}
          />
          <br />
          <Flex justifyContent="space-between">
            <Flex mr="20px">
              <CheckCircleIcon
                mr="12px"
                color={isPasswordBetween ? "bg.green" : "border.input"}
                boxSize="20px"
              />
              <Text
                color={isPasswordBetween ? "black" : "text.grey"}
                fontSize="sm"
              >
                Between 8 and 20 characters
              </Text>
            </Flex>
            <Flex>
              <CheckCircleIcon
                mr="12px"
                name="circleTick"
                color={isPasswordUsingNOrS ? "bg.green" : "border.input"}
                boxSize="20px"
              />
              <Text
                color={isPasswordUsingNOrS ? "black" : "text.grey"}
                fontSize="sm"
              >
                One number or a special character
              </Text>
            </Flex>
          </Flex>
          <Box mt={"20px"} pt="20px">
            <Box>
              <Text as={Box}>
                By activating, I agree to{" "}
                <TermsOfServiceLink
                  label={
                    <Text d="inline" color="brand.main">
                      Verivest’s Terms of Service
                    </Text>
                  }
                />{" "}
                and{" "}
                <PrivacyPolicyLink
                  label={
                    <Text d="inline" color="brand.main">
                      Privacy Policy.
                    </Text>
                  }
                />
              </Text>
            </Box>
            <Flex
              mt={{ base: "20px", md: "40px" }}
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Button
                isLoading={isLoading.state}
                label="Activate Account"
                type="submit"
                mt={"20px"}
                mr="20px"
                isLarge
                boxShadow={"buttonRaise"}
              />
            </Flex>
          </Box>
        </form>
      </AuthBox>
    </AuthContainer>
  );
};
