import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { HelpIcon } from "../../../icons";
import { VeriTooltip } from "../VeriTooltip";
import { VeriHelpToolTipProps } from "./VeriHelpToolTip.types";

const VeriHelpToolTip: React.FC<VeriHelpToolTipProps> = ({
  label,
  tooltipLabel,
  labelPosition = "right",
  tooltipHoverOnlyForIcon,
  tooltipProps,
  children,
  iconProps,
  ...rest
}) => {
  const [triggerRef, setTriggerRef] = React.useState<
    HTMLDivElement | SVGSVGElement | null
  >(null);

  return (
    <Box ref={tooltipHoverOnlyForIcon ? null : setTriggerRef} {...rest}>
      <Flex alignItems={"center"}>
        {label && labelPosition === "left" && <Box mr={2}>{label}</Box>}
        <HelpIcon
          ref={tooltipHoverOnlyForIcon ? setTriggerRef : null}
          boxSize={"16px"}
          color={"icons.grey"}
          {...iconProps}
        />
        {label && labelPosition === "right" && <Box ml={2}>{label}</Box>}
      </Flex>
      {triggerRef && (
        <VeriTooltip
          trigger={triggerRef}
          label={tooltipLabel}
          {...tooltipProps}
        >
          {children}
        </VeriTooltip>
      )}
    </Box>
  );
};

export default VeriHelpToolTip;
