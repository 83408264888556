import React from "react";
import { logError } from "../../error-handling/logError";
import { getApiErrorMessage } from "../../error-handling/useStandardApiErrorHandler";
import { useVeriToast } from "../ui";

export default function useGetDataOrDefaults<T>(
  defaults: T | undefined,
  getData: () => Promise<T>,
  effectDependencies?: React.DependencyList,
) {
  const [state, setState] = React.useState<T | undefined>(defaults);
  const { toastError } = useVeriToast();

  React.useEffect(
    () => {
      if (!defaults) {
        getData()
          .then((result) => setState(result))
          .catch((error) => {
            logError(error);
            const errorMessage = getApiErrorMessage(error);
            toastError(errorMessage);
          });
      }
    },
    effectDependencies ? [...effectDependencies, defaults] : [defaults],
  );

  return {
    data: state,
    refresh: async () => {
      const result = await getData();
      setState(result);
    },
  };
}
