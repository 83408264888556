import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { AuthBoxProps } from "./AuthBox.types";
import { AuthBoxHeader } from "./AuthBoxHeader";
import { Button } from "../../components";
import { ArrowLeftIcon } from "../../icons";
import { useHistory } from "react-router-dom";
import { buildRouteUrl } from "../../utils";
import { authRoutes } from "../../defines/routes/auth";
import { useAuthProcess } from "../AuthProcessProvider";

const AuthBox: React.FC<AuthBoxProps> = ({
  title,
  subtitle,
  centerTitles,
  children,
  titleLogoUrl,
  companyName,
  showBackButton,
  isSmHeader,
}) => {
  const history = useHistory();
  const { getAuthUrlParams } = useAuthProcess();

  return (
    <Box maxWidth={"600"}>
      {showBackButton && (
        <Button
          textProps={{
            textDecoration: "none",
          }}
          link
          onClick={() => {
            history.replace(
              buildRouteUrl(
                authRoutes.GET_STARTED,
                undefined,
                getAuthUrlParams(),
              ),
            );
          }}
        >
          <Flex alignItems="center" gap={1} ml={1}>
            <ArrowLeftIcon /> Back to login
          </Flex>
        </Button>
      )}
      <Box
        borderRadius={"8px"}
        bg={"bg.white"}
        boxShadow={3}
        width={{ base: "100%", md: "550px" }}
      >
        <Box
          px={{ base: "20px", md: "60px" }}
          py={{ base: "20px", md: "52px" }}
        >
          <Flex
            flexDirection={titleLogoUrl ? "row" : "column"}
            alignItems={centerTitles ? "center" : undefined}
            justifyContent={titleLogoUrl ? "space-between" : undefined}
            mb={isSmHeader ? 3 : 0}
          >
            <AuthBoxHeader
              centerTitles={centerTitles}
              titleLogoUrl={titleLogoUrl}
              title={title}
              subtitle={subtitle}
              companyName={companyName}
              isSmHeader={isSmHeader}
            />
          </Flex>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default AuthBox;
