import { Box, Image, Text } from "@chakra-ui/react";
import { VeriHeading } from "../../..";
import React from "react";

export const AuthBoxHeader = ({
  titleLogoUrl,
  centerTitles,
  title,
  subtitle,
  companyName,
  isSmHeader,
}: {
  titleLogoUrl?: string;
  centerTitles?: React.ReactNode | null;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  companyName?: string;
  isSmHeader?: boolean;
}) => {
  if (titleLogoUrl) {
    return (
      <>
        <Box>
          <VeriHeading
            fontSize={isSmHeader ? "xl" : "3xl"}
            as="h1"
            mb={isSmHeader ? 0 : 4}
            fontWeight="bold"
          >
            {title || "Sign in"}
          </VeriHeading>
          <Text
            fontWeight={isSmHeader ? 500 : undefined}
            textStyle="bodyRegular"
            textAlign={centerTitles ? "center" : undefined}
          >
            {subtitle || "To continue to your investor portal."}
          </Text>
        </Box>
        <Box>
          {titleLogoUrl ? (
            <Image
              height={"49px"}
              maxW={"135px"}
              alt={companyName + " logo"}
              src={titleLogoUrl}
            />
          ) : undefined}
        </Box>
      </>
    );
  }
  return (
    <>
      {title && (
        <VeriHeading
          textAlign={centerTitles ? "center" : undefined}
          fontSize="32px"
          as="h1"
          mb={"11px"}
          fontWeight="500"
        >
          {title}
        </VeriHeading>
      )}
      {subtitle && (
        <Text textAlign={centerTitles ? "center" : undefined}>{subtitle}</Text>
      )}
    </>
  );
};
