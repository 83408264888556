import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { AuthContainer } from "../../AuthContainer";
import { CheckCircleIcon } from "../../../icons";
import { VeriHeading } from "../../../components";

export const VerifyAccount: React.FC = () => {
  return (
    <AuthContainer>
      <Box textAlign="center" mx={8}>
        <CheckCircleIcon
          boxSize={"60px"}
          color={"bg.green"}
          mb={{ base: "20px", md: "60px" }}
        />
        <VeriHeading fontSize="56px" mb="20px">
          Sign Up Confirmed
        </VeriHeading>
        <Text fontSize="20px">
          Please follow the instructions in your email.
        </Text>
        <Flex justifyContent="center" mt="44px">
          <img alt="email" src="/images/mail-icon.svg" />
        </Flex>
      </Box>
    </AuthContainer>
  );
};
