import React from "react";
import { Route } from "react-router-dom";
import { authRoutes } from "@verivest/shared-components/src/defines/routes/auth";
import { VeriAsyncRouteComponentType } from "@verivest/shared-components/src/defines/after-js-types";
import {
  ActivateUser,
  ConfirmActivation,
  GetStarted,
  Login,
  ResetPassword,
  ResetPasswordConfirm,
  Signup,
  VerifyAccount,
} from "@verivest/shared-components/src/auth/pages";
import { AuthProcessProvider } from "@verivest/shared-components/src/auth/AuthProcessProvider";
import { isClient } from "@verivest/shared-components/";
import { AuthQueryParams } from "@verivest/shared-components/src/auth/types/AuthQueryParams";
import { getURLParams } from "../../utils/utils";
import Auth from "../../auth/Auth";
import {
  InvestorPortalAuthMatchParams,
  InvestorPortalAuthServerProps,
  loadAuthServerData,
} from "@verivest/shared-components/src/auth/helpers/loadAuthServerData";
import useGetDataOrDefaults from "@verivest/shared-components/src/hooks/data/useGetDataOrDefaults";
import { useAuthGetUrlQueryParams } from "@verivest/shared-components/src/auth/useLoadAuthServerData";
import { LoginValidateMFA } from "@verivest/shared-components/src/auth/pages/Login/ValidateMFA";
import { useBooleanState } from "@verivest/shared-components";
import DynamicConfigFlags from "@verivest/shared-components/src/dynamic-config/configs";
import { ManagerModel } from "@verivest/shared-components/src/api/models";

const InvestorPortalAuth: VeriAsyncRouteComponentType<
  InvestorPortalAuthServerProps,
  InvestorPortalAuthMatchParams
> = (serverProps) => {
  const getAuthProps = useAuthGetUrlQueryParams(serverProps.match.params);
  const isLoading = useBooleanState(false);
  const { data: props, refresh } = useGetDataOrDefaults(
    serverProps,
    async () => {
      isLoading.true();
      const result = await getAuthProps();
      isLoading.false();

      return result;
    },
  );

  React.useEffect(() => {
    refresh();
  }, [serverProps.match.params.page]);

  if (!props) return <></>;

  return (
    <AuthProcessProvider authData={props.authData || {}}>
      <Route
        path={authRoutes.GET_STARTED}
        render={() => <GetStarted />}
        exact
      />
      <Route
        path={authRoutes.LOGIN}
        render={() => (
          <Login
            currentUser={props.currentUser}
            inviteCode={props.inviteCode}
          />
        )}
        exact
      />
      <Route
        path={authRoutes.LOGIN_MFA}
        render={() => <LoginValidateMFA />}
        exact
      />
      <Route path={authRoutes.SIGNUP} render={() => <Signup />} exact />
      <Route
        path={authRoutes.SIGNUP_CONFIRM}
        render={() => <ConfirmActivation />}
        exact
      />
      <Route
        path={authRoutes.SIGNUP_ACTIVATION}
        render={() => (
          <ActivateUser email={props?.email || ""} token={props?.token || ""} />
        )}
        exact
      />
      <Route
        path={authRoutes.RESET_PASSWORD}
        render={() => <ResetPassword />}
        exact
      />
      <Route
        path={authRoutes.RESET_PASSWORD_CONFIRM}
        render={() => <ResetPasswordConfirm />}
        exact
      />
      <Route path={authRoutes.VERIFY} render={() => <VerifyAccount />} exact />
      <Route path={"/auth"} render={() => <GetStarted />} exact />
    </AuthProcessProvider>
  );
};

InvestorPortalAuth.getInitialProps = async (rest) => {
  let query: AuthQueryParams;
  const auth: Auth = (rest as never as { auth: Auth }).auth;
  const manager = rest.dynamicConfig.get(
    DynamicConfigFlags.MANAGER,
  ) as ManagerModel;

  if (isClient()) {
    query = getURLParams(rest.location?.search || "") || {};
  } else {
    query = rest.req?.query || {};
  }

  const result = await loadAuthServerData(
    manager,
    query,
    rest.match.params,
    auth,
    rest.req?.hostname || "",
  );

  /*if (query.email) {
    const user = await auth.loadUserByEmail(query.email);
    if (!user || !user.user_id) {
      return { redirectTo: "/signup?" + qs.stringify(query) };
    } else {
      return { redirectTo: "/login?" + qs.stringify(query) };
    }
  }*/

  return result;
};

InvestorPortalAuth.getChunkName = () => "investor-portal-auth";

export default InvestorPortalAuth;
