import { useAuth } from "./index";
import { useLocation } from "react-router-dom";
import {
  InvestorPortalAuthMatchParams,
  loadAuthServerData,
} from "./helpers/loadAuthServerData";
import { getURLParams } from "../utils";
import { AuthQueryParams } from "./types/AuthQueryParams";
import useGetDynamicConfigManager from "../dynamic-config/hooks/useGetDynamicConfigManager";

export const useAuthGetUrlQueryParams = (
  params: InvestorPortalAuthMatchParams,
) => {
  const { auth } = useAuth();
  const location = useLocation();
  const query = getURLParams<AuthQueryParams>(location?.search || "") || {};
  const manager = useGetDynamicConfigManager();

  return () =>
    loadAuthServerData(manager, query, params, auth, window.location.hostname);
};
