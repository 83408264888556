import { Alert, AlertIcon, Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { TwoWayFactorAuthenticationModal } from "../../../components/modals/TwoWayFactorAuthenticationModal";
import React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { UserModel } from "../../../api/models";
import { Button, VeriInput } from "../../../components";
import { managerDashboardRoutes } from "../../../defines";
import { authRoutes } from "../../../defines/routes/auth";
import { useBooleanState, useVeriToast } from "../../../hooks";
import { useVeriForm } from "../../../react-hook-form";
import { buildRouteUrl, isValidEmail } from "../../../utils";
import { AuthBox } from "../../AuthBox";
import { AuthContainer } from "../../AuthContainer";
import { useAuthProcess } from "../../AuthProcessProvider";
import { useAuth } from "../../index";
import { AuthFormData } from "../../types/AuthFormData";
import { useAuthGetUrlQueryParams } from "../../useAuthGetUrlQueryParams";

export const Login: React.FC<{
  currentUser?: { profile?: UserModel };
  inviteCode?: string;
}> = ({ currentUser, inviteCode }) => {
  const history = useHistory();
  const { auth } = useAuth();
  const { authData, isAdmin, setAuthData, getAuthUrlParams } = useAuthProcess();
  const toast = useVeriToast();
  const [showMFA, setShowMFA] = React.useState(false);
  const form = useVeriForm<AuthFormData>();

  const showCurrentUser = (currentUser && currentUser.profile && true) || false;

  const [error, setError] = React.useState<string>("");
  const isLoading = useBooleanState(false);
  const isLoaded = useBooleanState(false);
  const urlParams = useAuthGetUrlQueryParams();

  const email = currentUser?.profile?.email || urlParams.email || "";

  const login = form.handleSubmit(async (data) => {
    isLoading.true();
    setError("");

    const remember = true;

    try {
      const response = await auth.login(
        {
          ...data,
          email: data.email || email || "",
          invite_code: inviteCode,
          remember,
        },
        //`${authData.template ? `?t=${authData.template}` : ""}`,
      );

      if (response && response.error) {
        if (response.error.code === "NO_USER_FOUND_EMAIL") {
          setError(
            "A user with this email was not found, try again or Sign Up",
          );
        } else if (response.error.code === "NO_EMAIL_PASSWORD_MATCH") {
          setError("Email and passwords do not match, please try again");
        } else if (response.error.code === "NO_PASSWORD_SET") {
          // should not happen since we import all the passwords
          setError("Email and passwords do not match, please try again");
        } else if (response.error.code === "USER_EMAIL_NOT_VERIFIED") {
          setError("Email is not verified");
        } else {
          /*changeState({ error: response.error, isLoading: false });*/
        }

        isLoading.false();
      } else if (response) {
        if (response.mfa_required === true) {
          authData.remember = remember;
          authData.mfaToken = response.token;
          setAuthData(authData);
          if (
            response.is_mallcop &&
            response.mfa_type !== "app" &&
            response.mfa_type !== "sms"
          ) {
            auth.setMFAToken(response.token);
            setShowMFA(true);
          } else {
            history.push(
              buildRouteUrl(authRoutes.LOGIN_MFA, undefined, {
                type: response.mfa_type,
                ...(response.mfa_masked_phone
                  ? { mp: response.mfa_masked_phone }
                  : {}),
              }),
            );
          }
        } else {
          await auth.loginThroughToken(response.token);

          // Redirect to the manager dashboard if user is a manager
          // and no back url was defined
          if (
            !authData.backUrl &&
            (auth.isManagerOfAny() || auth.isMallcop())
          ) {
            window.location.href = managerDashboardRoutes.HOME;
          } else {
            window.location.href = authData.backUrl || "/";
          }
        }
      }
    } catch (e) {
      toast.toastServerError(e);
      isLoading.false();
    }
  });

  React.useEffect(() => {
    setTimeout(isLoaded.true.bind(null), 100);
  }, []);

  if (!isLoaded.state) return <></>;

  return (
    <AuthContainer>
      <AuthBox
        titleLogoUrl={authData?.printLogoUrl}
        companyName={authData?.name}
        title={"Sign in"}
        subtitle={
          isAdmin
            ? "to continue to Admin"
            : "to continue to your investor portal"
        }
      >
        <form onSubmit={login} noValidate>
          {error && (
            <Alert status="error" mb={{ base: "20px", md: "40px" }} mt={7}>
              <AlertIcon />
              {error}
            </Alert>
          )}
          <Box mt={error ? 1 : 7}>
            {!showCurrentUser && (
              <VeriInput
                {...form.register("email", {
                  required: "Email is required",
                  validate: (value) =>
                    isValidEmail(value) ? undefined : "Email is not valid",
                })}
                label="Email address"
                propsInput={{ placeholder: "Email", "data-private": true }}
                error={form.formState.errors.email?.message}
              />
            )}
            {showCurrentUser && (
              <Box mb="47px">
                <Box
                  // onClick={() => auth.loginWithSession(s)}
                  display="flex"
                  py={"10px"}
                  borderColor="border.input"
                  _last={{ borderBottomWidth: "0" }}
                  color="black"
                  fontSize="16px"
                  letterSpacing="0"
                  lineHeight="22px"
                  textAlign="center"
                  alignItems="center"
                >
                  <Avatar
                    width="48px"
                    height="48px"
                    name={currentUser?.profile?.name || ""}
                    src={currentUser?.profile?.picture || ""}
                    mr="16px"
                  />
                  <Box textAlign="left">
                    <Text
                      data-private
                      fontSize="16px"
                      fontWeight="500"
                      lineHeight="24px"
                    >
                      {currentUser?.profile?.email}
                    </Text>
                    <Text
                      as={RouterLink}
                      color="brand.main"
                      to={buildRouteUrl(
                        authRoutes.GET_STARTED,
                        undefined,
                        getAuthUrlParams(),
                      )}
                      fontSize="16px"
                    >
                      Not You?
                    </Text>
                  </Box>
                </Box>

                <Flex justifyContent="center"></Flex>
              </Box>
            )}
            <VeriInput
              {...form.register("password", {
                required: "Password is required",
              })}
              labelExtra={
                <Text
                  as={RouterLink}
                  color={"brand.main"}
                  to={buildRouteUrl(
                    authRoutes.RESET_PASSWORD,
                    undefined,
                    getAuthUrlParams(authData),
                  )}
                  fontWeight="400"
                  fontSize="16px"
                >
                  Forgot password?{" "}
                </Text>
              }
              label="Password"
              propsInput={{ placeholder: "Password", autoFocus: true }}
              type="password"
              error={form.formState.errors.password?.message}
            />
            <Flex
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              width={{ base: "100%", md: "48%" }}
            >
              <Button
                as={"button"}
                isLarge
                isLoading={isLoading.state}
                label="Sign in"
                type="submit"
                mt={"20px"}
                boxShadow={"buttonRaise"}
                w="100%"
              />
            </Flex>
          </Box>
          {showMFA && (
            <TwoWayFactorAuthenticationModal
              isOpen
              onClose={() => {
                isLoading.false();
                setShowMFA(false);
              }}
            />
          )}
        </form>
      </AuthBox>
    </AuthContainer>
  );
};
