import { Alert, AlertIcon, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import useAuth from "../../useAuth";
import { useAuthProcess } from "../../AuthProcessProvider";
import { useToastError } from "../../../hooks/ui/toast/useToastError";
import { useVeriForm } from "../../../react-hook-form";
import { AuthFormData } from "../../types/AuthFormData";
import { useBooleanState } from "../../../hooks";
import { AuthContainer } from "../../AuthContainer";
import { AuthBox } from "../../AuthBox";
import { Button, VeriInput } from "../../../components";
import { ManagerLogoPlaceholder } from "../../ManagerLogoPlaceholder/ManagerLogoPlaceholder";
import { buildRouteUrl, isValidEmail } from "../../../utils";
import { authRoutes } from "../../../defines/routes/auth";

export const ResetPassword = () => {
  const location = useLocation();
  const { auth } = useAuth();
  const { getAuthUrlParams, authData } = useAuthProcess();
  const toastError = useToastError();

  const form = useVeriForm<AuthFormData>();

  const [error, setError] = React.useState<string | null>(null);
  const isLoading = useBooleanState(false);
  const isFinished = useBooleanState(false);

  const email = form.watch("email");

  const sendResetPassword = form.handleSubmit(async (data) => {
    isLoading.true();
    setError("");

    try {
      const response = await auth.resetPassword(data.email);
      if (response && response.error) {
        isLoading.false();
        return setError(
          "If a user exists with that email, a link will be sent to reset the password.",
        );
      }
      isFinished.true();
    } catch (e) {
      toastError("Something went wrong");
    }

    isLoading.false();
  });

  if (isFinished.state) {
    return (
      <AuthContainer>
        <AuthBox
          location={location}
          centerTitles
          companyName={authData?.name}
          title="Check your email to set a new password"
          subtitle="You’ll receive a link to set a new password if we find an account with the email address you provided.
        If you have an account, but you don’t receive an email, please check your spam folder or contact us."
        >
          <Flex mt={"20px"} flexDirection="column" alignItems="center">
            <Button
              label="Back to sign in"
              boxShadow={"buttonRaise"}
              mb={5}
              to={buildRouteUrl(authRoutes.LOGIN, undefined, {
                ...getAuthUrlParams(),
                email,
              })}
              isLarge
            />
          </Flex>
        </AuthBox>
      </AuthContainer>
    );
  }

  return (
    <AuthContainer>
      <AuthBox
        companyName={authData?.name}
        location={location}
        centerTitles
        title="Forgot your password?"
        subtitle="Enter your email address and we’ll send you a link to reset your password."
      >
        <>
          {authData?.printLogoUrl ? (
            <Image
              marginX={"auto"}
              marginBottom={"68px"}
              height={"108px"}
              maxW={"300px"}
              alt={"Your investor portal logo"}
              src={authData?.printLogoUrl}
            />
          ) : (
            <ManagerLogoPlaceholder />
          )}
          <form onSubmit={sendResetPassword} noValidate>
            {error && (
              <Alert status="error" mb={{ base: "20px", md: "40px" }}>
                <AlertIcon />
                {error}
              </Alert>
            )}
            <VeriInput
              label="Email address"
              {...form.register("email", {
                required: "Email is required",
                validate: (value) =>
                  isValidEmail(value) ? undefined : "Email is not valid",
              })}
              propsInput={{ placeholder: "Email" }}
              error={form.formState.errors.email?.message}
            />
            <Flex mt={"20px"} flexDirection="column" alignItems="center">
              <Button
                isLarge
                isLoading={isLoading.state}
                label="Reset password"
                type="submit"
                as={"button"}
                boxShadow={"buttonRaise"}
                mt={5}
                mb={5}
              />
              <Text
                as={RouterLink}
                color="brand.main"
                to={buildRouteUrl(
                  authRoutes.GET_STARTED,
                  undefined,
                  getAuthUrlParams(),
                )}
                fontSize="16px"
              >
                Cancel
              </Text>
            </Flex>
          </form>
        </>
      </AuthBox>
    </AuthContainer>
  );
};
