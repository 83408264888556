import React from "react";
import { VeriInput } from "../VeriInput";
import { VeriPasswordInputProps } from "./VeriPasswordInput.types";

export const VeriPasswordInput = React.forwardRef<
  HTMLInputElement,
  VeriPasswordInputProps
>((props, ref) => {
  return <VeriInput type={"password"} {...props} ref={ref} />;
});

VeriPasswordInput.displayName = "VeriPasswordInput";
