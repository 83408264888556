import { AuthTemplates } from "../defines/auth";

interface AuthTemplate {
  showCompanyInSignUp?: boolean;
}

const managerTemplate: AuthTemplate = {
  showCompanyInSignUp: true,
};

export const templates: { [key in AuthTemplates]: AuthTemplate | undefined } = {
  [AuthTemplates.manager]: managerTemplate,
  [AuthTemplates.summit]: undefined,
} as const;

export const getAuthCustomTemplate = (templateName: string) => {
  return templates[templateName as AuthTemplates] || null;
};
