import { Box } from "@chakra-ui/react";
import React from "react";
import { useAuth } from "../../index";
import { buildRouteUrl, getURLParams } from "../../../utils";
import { AuthQueryParams } from "../../types/AuthQueryParams";
import { useLocation } from "react-router-dom";
import { AuthContainer } from "../../AuthContainer";
import { VeriSpinner } from "../../../components/common/loaders/VeriSpinner";
import { CheckCircleIcon, CloseIcon } from "../../../icons";
import { VeriHeading } from "../../../components";
import { investorPortalRoutes } from "../../../defines";

export const ConfirmActivation: React.FC = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const [activationState, setActivationState] = React.useState({
    isActive: false,
    isLoading: true,
  });

  React.useEffect(() => {
    async function fetchData() {
      const { token, invite_code } = getURLParams<AuthQueryParams>(
        location.search,
      );
      const response = await auth.signupConfirm(token || "", invite_code || "");
      if (response.error) {
        return setActivationState({ isActive: false, isLoading: false });
      }

      setTimeout(() => {
        window.location.href = buildRouteUrl(investorPortalRoutes.home);
      }, 2500); //2.5 seconds

      return setActivationState({ isActive: true, isLoading: false });
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading, isActive } = activationState;
  return (
    <AuthContainer>
      <Box textAlign="center">
        {isLoading && <VeriSpinner size="xl" />}
        {!isLoading && isActive && (
          <>
            <CheckCircleIcon
              boxSize={"60px"}
              color={"bg.green"}
              mb={{ base: "20px", md: "60px" }}
            />
            <VeriHeading fontSize="56px" mb="20px">
              Thanks for activating your account
            </VeriHeading>
          </>
        )}

        {!isLoading && !isActive && (
          <>
            <CloseIcon
              boxSize={"60px"}
              color={"red"}
              mb={{ base: "20px", md: "60px" }}
            />
            <VeriHeading fontSize="56px" mb="20px">
              Something went wrong
            </VeriHeading>
          </>
        )}
      </Box>
    </AuthContainer>
  );
};
