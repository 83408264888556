import { Alert, AlertIcon, Box, Flex } from "@chakra-ui/react";
import React, { FormEvent } from "react";
import { useLocation } from "react-router-dom";
import { Button, MfaCodeInput } from "../../../components";
import { managerDashboardRoutes } from "../../../defines";
import { logError } from "../../../error-handling/logError";
import { AuthBox } from "../../AuthBox";
import { AuthContainer } from "../../AuthContainer";
import { useAuthProcess } from "../../AuthProcessProvider";
import useAuth from "../../useAuth";

interface MFAState {
  data?: {
    code?: string;
  };
  error?: boolean | string;
  isLoading?: boolean;
  codeSent?: boolean;
}

export const LoginValidateMFA = () => {
  const { auth } = useAuth();
  const { authData } = useAuthProcess();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const maskedPhone = urlParams.get("mp") || "";
  const mfaType = urlParams.get("type") || "";
  const [mfaState, setMFAState] = React.useState<MFAState>({
    data: {
      code: "",
    },
    error: false,
    isLoading: false,
    codeSent: false,
  });

  const changeState = (val: MFAState) => {
    return setMFAState((old) => ({
      ...old,
      ...val,
    }));
  };
  const onChangeHandler = (name: string) => (value: string) =>
    setMFAState((old) => ({ ...old, data: { ...old.data, [name]: value } }));

  const submitCode = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    changeState({ isLoading: true, error: false });
    const { code } = mfaState.data || { code: "" };
    if (!code || code.length !== 6) {
      changeState({
        isLoading: false,
        error: "You need to enter the 6 digits code",
      });
    }

    try {
      const response = await auth.loginMFA(authData.mfaToken || "", code || "");
      if (response && response.error) {
        return changeState({ error: response.error.detail, isLoading: false });
      } else if (response) {
        await auth.loginThroughToken(response.token);

        // Redirect to the manager dashboard if user is a manager
        // and no back url was defined
        if (!authData.backUrl && (auth.isManagerOfAny() || auth.isMallcop())) {
          window.location.href = managerDashboardRoutes.HOME;
        } else {
          window.location.href = authData.backUrl || "/";
        }
      }

      return changeState({ error: false, isLoading: false });
    } catch (e) {
      logError("LoginForm.submitCode", e);
    }
    return changeState({ error: "Something went wrong", isLoading: false });
  };

  const { error, isLoading } = mfaState;

  if (auth)
    return (
      <AuthContainer>
        <AuthBox
          title="Two-step verification"
          subtitle={
            mfaType === "app"
              ? "Get a 6-digit confirmation code via authenticator app."
              : `Confirm your account by entering the code we sent to ${maskedPhone}`
          }
        >
          <form onSubmit={submitCode} noValidate>
            {error && (
              <Alert status="error" my={{ base: "20px", md: "40px" }}>
                <AlertIcon />
                {error}
              </Alert>
            )}

            <Box>
              <MfaCodeInput
                onComplete={onChangeHandler("code")}
                fieldWidth="32px"
              />
              <Flex
                mt={"20px"}
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Button
                  isLarge
                  width={{ base: "100%", md: "unset" }}
                  isLoading={isLoading}
                  label="Sign in securely"
                  type="submit"
                  mt={"20px"}
                />
              </Flex>
            </Box>
          </form>
        </AuthBox>
      </AuthContainer>
    );

  return <></>;
};
