import { Box, BoxProps, Flex } from "@chakra-ui/react";
import React from "react";

const AuthContainer: React.FC<BoxProps> = ({ children }) => {
  return (
    <Box marginBottom="100px" marginTop="146">
      <Flex paddingTop={{ base: "40px", lg: "33px" }} justifyContent="center">
        {children}
      </Flex>
      <Box textAlign={"center"} mt={"22px"}>
        Powered by Verivest
      </Box>
    </Box>
  );
};

export default AuthContainer;
