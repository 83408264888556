import { Box, Text } from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import React from "react";
import { Button } from "../../../../components";
import { useStandardApiHandler } from "../../../../error-handling/useStandardApiErrorHandler";
import { useAuthProcess } from "../../../AuthProcessProvider";
import useAuth from "../../../useAuth";

/**
 * For testing, must use localhost
 * @See Auth's readme
 */
export const CustomGoogleLoginButtonContent = () => {
  const { auth } = useAuth();
  const { authData } = useAuthProcess();

  const { wrapApiRequest, isLoading } = useStandardApiHandler();

  const login = useGoogleLogin({
    onSuccess: wrapApiRequest(async (tokenResponse) => {
      const resp = await auth.initGoogleLogin({
        token: tokenResponse.access_token,
      });

      window.location.replace(
        authData?.callbackUrl +
          "?token=" +
          resp.token +
          (authData?.backUrl ? `&back_to=${authData.backUrl}` : "") +
          '&stay_signed_in="1"',
      );
    }),
    onError: wrapApiRequest((error) => {
      throw error;
    }),
  });

  return (
    <Box width="100%">
      <Button
        isLoading={isLoading}
        color="brand.main" // This is the color used by the loader
        bg="bg.white"
        w={"full"}
        h="48px"
        borderColor="#C9CDD6"
        _hover={{ bg: "bg.white" }}
        leftIcon={GoogleImage}
        leftIconProps={{ style: { marginRight: "10px" } }}
        borderWidth="1px"
        onClick={() => login()}
      >
        <Text
          textStyle={"bodyRegularBold"}
          color={"text.black"}
          fontFamily="Nunito Sans"
          fontSize="16px"
        >
          Sign in with Google
        </Text>
      </Button>
    </Box>
  );
};

const GoogleImage = () => {
  return (
    <img
      alt="email"
      src="/images/google.svg"
      width="22px"
      style={{ marginRight: "10px" }}
    />
  );
};
