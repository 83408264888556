import { ADMIN_DOMAIN_NAME } from "../defines";

export const isAdminDomain = (domain: string) => {
  return domain === ADMIN_DOMAIN_NAME;
};

export const isValidWhiteLabelDomain = (domain: string) => {
  return (
    domain !== process.env.RAZZLE_APP_VERIVEST_DOMAIN && !isAdminDomain(domain)
  );
};
