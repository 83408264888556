import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, VeriInput } from "../../../components";
import { authRoutes } from "../../../defines/routes/auth";
import { useStandardApiHandler } from "../../../error-handling/useStandardApiErrorHandler";
import { useBooleanState } from "../../../hooks";
import { useDidMount } from "../../../lifecycle/useDidMount";
import { useVeriForm } from "../../../react-hook-form";
import { buildRouteUrl, isValidEmail } from "../../../utils";
import { AuthBox } from "../../AuthBox";
import { AuthContainer } from "../../AuthContainer";
import { useAuthProcess } from "../../AuthProcessProvider";
import { useAuth } from "../../index";
import { ManagerLogoPlaceholder } from "../../ManagerLogoPlaceholder/ManagerLogoPlaceholder";
import { AuthFormData } from "../../types/AuthFormData";
import { CustomGoogleLoginButton } from "./CustomGoogleLoginButton";

interface GetStartedProps {
  allowSocialLogin?: boolean;
}

export const GetStarted: React.FC<GetStartedProps> = ({ allowSocialLogin }) => {
  const { auth } = useAuth();
  const { authData, getAuthUrlParams, isAdmin } = useAuthProcess();
  const history = useHistory();

  const form = useVeriForm<AuthFormData>();

  const { isLoading, wrapApiRequest } = useStandardApiHandler();

  const onSubmit = form.handleSubmit(
    wrapApiRequest(async (data) => {
      const user = await auth.loadUserByEmail(data.email);
      if (!user || !user.user_id) {
        history.push(
          buildRouteUrl(authRoutes.SIGNUP, undefined, {
            ...getAuthUrlParams(),
            email: data.email,
          }),
        );
      } else {
        history.push(
          buildRouteUrl(authRoutes.LOGIN, undefined, {
            ...getAuthUrlParams(),
            email: data.email,
          }),
        );
      }
    }),
  );

  const isLoaded = useBooleanState(false);

  useDidMount(() => {
    setTimeout(isLoaded.true.bind(null), 100);
  });

  if (!isLoaded.state) return <></>;

  return (
    <AuthContainer>
      <AuthBox
        title={"Login or Sign up"}
        subtitle={
          isAdmin
            ? "Access to Verivest admin"
            : `Access your ${
                (authData && authData.name) || "Verivest"
              } investor portal.`
        }
        centerTitles
        isSmHeader
      >
        <>
          {authData?.printLogoUrl ? (
            <Image
              marginX={"auto"}
              marginBottom={5}
              height={"108px"}
              maxW={"300px"}
              alt={"Verivest's logo"}
              fallbackSrc={authData?.printLogoUrl}
              src={authData?.printLogoUrl}
            />
          ) : (
            <ManagerLogoPlaceholder />
          )}
          <form onSubmit={onSubmit} noValidate>
            <div>
              <VeriInput
                {...form.register("email", {
                  required: "Email is required",
                  validate: (value) =>
                    isValidEmail(value) ? undefined : "Email is not valid",
                })}
                label="Email address"
                propsInput={{ placeholder: "Email", autoFocus: true }}
                error={form.formState.errors.email?.message}
              />
              <Flex mt={"20px"} alignItems="center" flexWrap="wrap" gap={5}>
                <Button
                  as={"button"}
                  isLarge
                  width={"50%"}
                  isLoading={isLoading}
                  label="Login"
                  type="submit"
                  mt={"20px"}
                  boxShadow={"buttonRaise"}
                  flex="1"
                />
                <Button
                  as={"button"}
                  isLarge
                  width={"50%"}
                  isLoading={isLoading}
                  label="Sign Up"
                  type="submit"
                  mt={"20px"}
                  boxShadow={"buttonRaise"}
                  outline
                  flex="1"
                />
              </Flex>
              {allowSocialLogin && (
                <>
                  <Box
                    textAlign="center"
                    borderBottom="1px solid"
                    borderColor={"border.grey"}
                    lineHeight="0px"
                    my={8}
                  >
                    <Text
                      as="span"
                      backgroundColor={"bg.white"}
                      color="text.grey"
                      px={6}
                    >
                      OR
                    </Text>
                  </Box>
                  <Flex justifyContent={"center"}>
                    <CustomGoogleLoginButton />
                  </Flex>
                </>
              )}
            </div>
          </form>
        </>
      </AuthBox>
    </AuthContainer>
  );
};
