import React from "react";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "../../../icons";
import { PasswordRequirementsCheckProps } from "./VeriPasswordInput.types";
import {
  PASSWORD_ALLOWED_SPECIAL_CHARACTERS,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
} from "../../../defines/password";

const allowedCharacters = PASSWORD_ALLOWED_SPECIAL_CHARACTERS.map((character) =>
  character.replace("\\-", "-"),
).join(" ");

export const PasswordRequirementsCheck = React.forwardRef<
  HTMLDivElement,
  PasswordRequirementsCheckProps
>(({ password }, ref) => {
  const passwordEntered = !!password;
  const isPasswordOfCorrectLength =
    passwordEntered &&
    password?.length >= PASSWORD_MIN_LENGTH &&
    password?.length <= PASSWORD_MAX_LENGTH;

  const onlySymbolsString = password?.replace(/[A-Z\d]/gi, "") || "";
  const passwordHasSymbols = !!onlySymbolsString;

  const passwordContainAtLeastOneNumber =
    passwordEntered && !!password?.match(/\d/g);

  const passwordContainAtLeastOneLetter =
    passwordEntered && !!password?.match(/[A-Z]/gi);

  const passwordContainAtLeastOneSymbol =
    passwordEntered &&
    !!onlySymbolsString.match(
      new RegExp(`^[${PASSWORD_ALLOWED_SPECIAL_CHARACTERS.join("")}]+$`),
    );

  const passwordContainAtLeastOneSymbolOrNumber =
    passwordContainAtLeastOneNumber
      ? (passwordHasSymbols &&
          passwordContainAtLeastOneSymbol &&
          passwordContainAtLeastOneNumber) ||
        (!passwordHasSymbols && passwordContainAtLeastOneNumber)
      : passwordContainAtLeastOneSymbol;

  return (
    <Stack ref={ref} spacing={2}>
      <Box>Password requirements</Box>
      <Flex
        w={"100%"}
        color={isPasswordOfCorrectLength ? "brand.main" : undefined}
      >
        <Box w={6}>
          <CheckIcon />
        </Box>
        <Box flexGrow={1}>At least {PASSWORD_MIN_LENGTH} characters</Box>
      </Flex>
      <Flex
        w={"100%"}
        color={passwordContainAtLeastOneLetter ? "brand.main" : undefined}
      >
        <Box w={6}>
          <CheckIcon />
        </Box>
        <Box flexGrow={1}>
          Include at least one letter (uppercase or lowercase)
        </Box>
      </Flex>
      <Flex
        w={"100%"}
        color={
          passwordContainAtLeastOneSymbolOrNumber ? "brand.main" : undefined
        }
      >
        <Box w={6}>
          <CheckIcon />
        </Box>
        <Box flexGrow={1}>
          Include at least one digit (0-9) or special character (
          {allowedCharacters})
        </Box>
      </Flex>
      {passwordHasSymbols && !passwordContainAtLeastOneSymbol && (
        <Flex w={"100%"} color={"semantic.error"}>
          <Box w={6}>
            <CloseIcon />
          </Box>
          <Box flexGrow={1}>
            Only the following characters are allowed: {allowedCharacters}
          </Box>
        </Flex>
      )}
      {(password?.length || 0) >= PASSWORD_MAX_LENGTH && (
        <Flex w={"100%"} color={"semantic.error"}>
          <Box w={6}>
            <CloseIcon />
          </Box>
          <Box flexGrow={1}>
            Password must be at most {PASSWORD_MAX_LENGTH} characters
          </Box>
        </Flex>
      )}
    </Stack>
  );
});

PasswordRequirementsCheck.displayName = "PasswordRequirementsCheck";
