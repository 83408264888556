import { Alert, AlertIcon, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useAuth } from "../../index";
import { useAuthProcess } from "../../AuthProcessProvider";
import { useToastError } from "../../../hooks/ui/toast/useToastError";
import { useVeriForm } from "../../../react-hook-form";
import { AuthFormData } from "../../types/AuthFormData";
import { useBooleanState } from "../../../hooks";
import { buildRouteUrl, getURLParams, isValidPassword } from "../../../utils";
import { AuthQueryParams } from "../../types/AuthQueryParams";
import { AuthContainer } from "../../AuthContainer";
import { AuthBox } from "../../AuthBox";
import { Button, VeriInput } from "../../../components";
import { authRoutes } from "../../../defines/routes/auth";

export const ResetPasswordConfirm = () => {
  const location = useLocation();
  const { auth } = useAuth();
  const { authData, getAuthUrlParams, getFinalUrl } = useAuthProcess();
  const toastError = useToastError();

  const form = useVeriForm<AuthFormData>();

  const [error, setError] = React.useState<string | null>(null);
  const isLoading = useBooleanState(false);
  const isFinished = useBooleanState(false);

  const sendResetPasswordConfirm = form.handleSubmit(async (data) => {
    setError("");

    if (data.password !== data.confirmPassword) {
      return form.setError("confirmPassword", {
        message: "The two passwords must match",
      });
    }

    isLoading.true();

    try {
      const { token } = getURLParams<AuthQueryParams>(location.search || "");

      const response = await auth.resetPasswordConfirm({
        password: data.password,
        token: token,
      });
      if (response && response.error) {
        isLoading.false();
        return setError("Something went wrong");
      } else if (response) {
        authData.token = response.token;
        setTimeout(() => (window.location.href = getFinalUrl()), 3000);
      }

      isFinished.true();
    } catch (e) {
      toastError("Something went wrong");
    }

    isLoading.false();
  });

  if (isFinished.state) {
    return (
      <AuthContainer>
        <AuthBox
          location={location}
          centerTitles
          title="Reset Password Confirmation"
          subtitle="You will be redirected to the dashboard."
        >
          <></>
        </AuthBox>
      </AuthContainer>
    );
  }

  return (
    <AuthContainer>
      <AuthBox
        location={location}
        centerTitles
        title="Please enter your new password"
      >
        <form onSubmit={sendResetPasswordConfirm} noValidate>
          {error && (
            <Alert status="error" mb={{ base: "20px", md: "40px" }}>
              <AlertIcon />
              {error}
            </Alert>
          )}
          <VeriInput
            label="Password"
            {...form.register("password", {
              required: "Password is required",
              validate: (value) =>
                isValidPassword(value)
                  ? undefined
                  : "Password must be a minimum of 8 characters with one number or special character",
            })}
            type="password"
            error={form.formState.errors.password?.message}
            helperText="Password must be 8–20 characters and contain one number or special character."
          />
          <br />
          <VeriInput
            {...form.register("confirmPassword", {
              required: "Confirm password is required",
            })}
            label="Confirm password"
            type="password"
            error={form.formState.errors.confirmPassword?.message}
          />
          <Flex mt={"20px"} flexDirection="column" alignItems="center">
            <Button
              isLarge
              isLoading={isLoading.state}
              label="Set new password"
              type="submit"
              as={"button"}
              mt={5}
              mb={5}
              boxShadow={"buttonRaise"}
            />
            <Text
              as={RouterLink}
              color="brand.main"
              to={buildRouteUrl(
                authRoutes.GET_STARTED,
                undefined,
                getAuthUrlParams(),
              )}
              fontSize="16px"
            >
              Cancel
            </Text>
          </Flex>
        </form>
      </AuthBox>
    </AuthContainer>
  );
};
